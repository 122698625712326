<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0 py-5">
            <div class="card-title align-items-center">
              <h3 class="card-label">Today Devices Revenue</h3>
              <span class="d-block text-muted font-size-sm"
                ><span
                  class="label label-lg font-weight-bold label-light-primary label-inline"
                  >{{ devices.length }}</span
                ></span
              >
            </div>
            <div class="card-toolbar">
              <button
                @click.prevent="exportAsPDF"
                class="btn btn-light-primary font-weight-bold px-10 mr-3"
              >
                Export .pdf
              </button>
              <button
                @click.prevent="exportAsExcel"
                class="btn btn-light-primary font-weight-bold px-10"
              >
                Export .xlsx
              </button>
            </div>
          </div>
          <div class="card-body py-0">
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table
                    class="table table-head-custom table-vertical-center table-head-bg"
                  >
                    <thead>
                      <tr class="text-uppercase">
                        <th class="text-left pl-6">
                          <span
                            @click="orderDevicesByName"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{ 'text-primary': nameOrder.isActive }"
                            >
                              Name</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': nameOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="nameOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th>{{ $t('GENERAL.LOCATION') }}</th>
                        <th v-if="currentUserCompanyModule[0] === 'gaming'">
                          <span
                            @click="orderDevicesByTotal"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{ 'text-primary': coinOrder.isActive }"
                            >
                              Coin</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': coinOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="coinOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th v-if="currentUserCompanyModule[0] === 'gaming'">
                          <span
                            @click="orderDevicesByTotal"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{ 'text-primary': smsOrder.isActive }"
                            >
                              SMS</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': smsOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="smsOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th v-if="currentUserCompanyModule[0] === 'gaming'">
                          <span
                            @click="orderDevicesByTotal"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{ 'text-primary': loyaltyOrder.isActive }"
                            >
                              Loyalty</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': loyaltyOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="loyaltyOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th>
                          <span
                            @click="orderDevicesByTotal"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{ 'text-primary': totalOrder.isActive }"
                            >
                              Total</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': totalOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="totalOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th>
                          <span
                            @click="orderDevicesByTransactionsNumber"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{
                                'text-primary':
                                  transactionsNumberOrder.isActive,
                              }"
                            >
                              TN</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary':
                                  transactionsNumberOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="transactionsNumberOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th class="text-right pr-6">
                          <span
                            @click="orderDevicesByLastCheck"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{
                                'text-primary': lastCheckOrder.isActive,
                              }"
                            >
                              Last Check</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': lastCheckOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="lastCheckOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(device, key) in devices" :key="device.id">
                        <td :class="{ 'border-top-0': key === 0 }" class="pl-6">
                          <span
                            class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                            >{{ device.name }}</span
                          >
                          <div>
                            <span
                              v-for="(tag, key) in device.locationMeta.tags"
                              :key="key + '___' + key"
                              class="label label-sm label-light-primary label-inline mr-2"
                              >{{ tag }}</span
                            >
                          </div>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span class="font-weight-bolder">{{
                            device.locationMeta.title
                          }}</span>
                        </td>
                        <td
                          v-if="currentUserCompanyModule[0] === 'gaming'"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <span v-if="device.coinTotal > 0"
                            >{{ device.coinTotal | currencyFormat }}
                          </span>
                          <span v-else>0€</span>
                        </td>
                        <td
                          v-if="currentUserCompanyModule[0] === 'gaming'"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <span v-if="device.smsTotal > 0"
                            >{{ device.smsTotal | currencyFormat }}
                          </span>
                          <span v-else>0 </span>
                        </td>
                        <td
                          v-if="currentUserCompanyModule[0] === 'gaming'"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <span v-if="device.loyaltyTotal > 0"
                            >{{ device.loyaltyTotal | currencyFormat }}
                          </span>
                          <span v-else>0 </span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          {{ device.total | currencyFormat }}
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          {{ device.transactionsNumber }}
                        </td>
                        <td
                          :class="{ 'border-top-0': key === 0 }"
                          class="text-right pr-6"
                        >
                          {{ device.updatedAt | formatTime }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer py-6">
            <div class="d-flex justify-content-between">
              <span class="font-weight-bolder text-dark">Osnovica: </span>
              <span>{{ base }} <span> </span></span>
            </div>
            <div class="d-flex justify-content-between">
              <span class="font-weight-bolder text-dark">PDV: (25%)</span>
              <span>{{ tax }} <span> </span></span>
            </div>
            <div
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="d-flex justify-content-between mt-2"
            >
              <span class="font-size-lg font-weight-bolder text-dark"
                >Coin:
              </span>
              <span class="font-size-lg font-weight-bolder text-dark"
                >{{ coinTotal.toFixed(2) }} <span> </span
              ></span>
            </div>
            <div
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="d-flex justify-content-between"
            >
              <span class="font-size-lg font-weight-bolder text-dark"
                >SMS:
              </span>
              <span class="font-size-lg font-weight-bolder text-dark"
                >{{ smsTotal }} <span> </span
              ></span>
            </div>
            <div
              v-if="currentUserCompanyModule[0] === 'gaming'"
              class="d-flex justify-content-between"
            >
              <span class="font-size-lg font-weight-bolder text-dark"
                >Loyalty:
              </span>
              <span class="font-size-lg font-weight-bolder text-dark"
                >{{ loyaltyTotal }} <span> </span
              ></span>
            </div>
            <div class="d-flex justify-content-between">
              <span class="font-size-lg font-weight-bolder text-dark"
                >Ukupno:
              </span>
              <span class="font-size-lg font-weight-bolder text-dark"
                >{{ total }} <span> </span
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapGetters } from 'vuex'
import format from 'date-fns/format'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import axios from 'axios'
import ApiService from '@/core/services/api.service'

export default {
  name: 'TodayRevenue',
  data() {
    return {
      devices: [],
      currentTime: new Date(),
      base: 0,
      tax: 0,
      total: 0,
      coinTotal: 0,
      smsTotal: 0,
      loyaltyTotal: 0,
      isLoaded: false,
      activeTableView: 'simple',
      nameOrder: {
        query: 'asc',
        isActive: false,
      },
      locationOrder: {
        query: 'asc',
        isActive: false,
      },
      transactionsNumberOrder: {
        query: 'asc',
        isActive: false,
      },
      lastCheckOrder: {
        query: 'asc',
        isActive: false,
      },
      coinOrder: {
        query: 'asc',
        isActive: false,
      },
      smsOrder: {
        query: 'asc',
        isActive: false,
      },
      loyaltyOrder: {
        query: 'asc',
        isActive: false,
      },
      totalOrder: {
        query: 'asc',
        isActive: false,
      },
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentUserCompanyModule',
      'currentUserCompanyOib',
    ]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Today Devices Revenue', route: '/today-revenue/list' },
    ])

    // ?properties[]=name
    if (
      this.currentUserRole === 'ROLE_ROOT' ||
      this.currentUserRole === 'ROLE_SUPER_ADMIN'
    ) {
      this.getDevices(
        '${process.env.VUE_APP_ROUTE_API_URL}/api/v1/device_daily_cumulatives?order[name]=asc&pagination=false'
      ).then(({ data }) => {
        this.devices = [...data['hydra:member']]
        this.devices.forEach((device) => {
          this.coinTotal += device.coinTotal
          this.smsTotal += device.smsTotal
          this.loyaltyTotal += device.loyaltyTotal
          this.base += device.total * 0.8
          this.tax += device.total - device.total * 0.8
          this.total += device.total
        })
        this.base = this.currencyFormat(this.base)
        this.tax = this.currencyFormat(this.tax)
        this.total = this.currencyFormat(this.total)

        this.isLoaded = true
      })
    }

    if (this.currentUserRole === 'ROLE_ADMIN') {
      this.getDevices(
        `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/device_daily_cumulatives?oib=${this.currentUserCompanyOib}&order[name]=asc&pagination=false`
      ).then(({ data }) => {
        this.devices = [...data['hydra:member']]
        this.devices.forEach((device) => {
          this.coinTotal += device.coinTotal
          this.smsTotal += device.smsTotal
          this.loyaltyTotal += device.loyaltyTotal
          this.base += device.total * 0.8
          this.tax += device.total - device.total * 0.8
          this.total += device.total
        })
        this.base = this.currencyFormat(this.base)
        this.tax = this.currencyFormat(this.tax)
        this.total = this.currencyFormat(this.total)

        this.isLoaded = true
      })
    }
  },
  methods: {
    async getDevices(endpoint) {
      try {
        return await axios.get(endpoint)
      } catch (e) {
        throw new Error(e)
      }
    },
    currencyFormat(num) {
      return num
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },
    diffInMinutes(dateLeft, dateRight) {
      if (dateLeft) {
        return differenceInMinutes(new Date(dateLeft), new Date(dateRight))
      }
    },
    getTodayRevenue(imei) {
      const today = format(new Date(), 'yyyy-MM-dd')

      ApiService.get(
        'transactions',
        `?imei=${imei}&properties[]=amount&createdAt[after]=${today}&pagination=false`
      ).then(() => {
        // const transactions = data['hydra:member']
        // let total = 0
        // transactions.forEach(transaction => {
        //   total += parseFloat(transaction.amount)
        // })
      })
    },
    orderDevicesByName() {
      this.nameOrder.isActive = true

      if (this.nameOrder.query === 'asc') this.nameOrder.query = 'desc'
      else this.nameOrder.query = 'asc'

      this.isLoaded = false
      this.devices = []

      if (this.currentUserRole === 'ROLE_ADMIN') {
        ApiService.get(
          'device_daily_cumulatives',
          `?oib=${this.currentUserCompanyOib}&order[name]=${this.nameOrder.query}&pagination=false`
        ).then(({ data }) => {
          this.devices = [...data['hydra:member']]
          this.isLoaded = true
        })
      }
    },
    orderDevicesByLocation() {
      this.locationOrder.isActive = true

      if (this.locationOrder.query === 'asc') {
        this.devices.sort((a, b) => a.locationMeta.title - b.locationMeta.title)
        this.locationOrder.query = 'desc'
      } else {
        this.devices.sort((a, b) => b.locationMeta.title - a.locationMeta.title)
        this.locationOrder.query = 'asc'
      }
    },
    orderDevicesByTransactionsNumber() {
      this.transactionsNumberOrder.isActive = true

      if (this.transactionsNumberOrder.query === 'asc')
        this.transactionsNumberOrder.query = 'desc'
      else this.transactionsNumberOrder.query = 'asc'

      this.isLoaded = false
      this.devices = []

      if (this.currentUserRole === 'ROLE_ADMIN') {
        ApiService.get(
          'device_daily_cumulatives',
          `?oib=${this.currentUserCompanyOib}&order[total]=${this.transactionsNumberOrder.query}&pagination=false`
        ).then(({ data }) => {
          this.devices = [...data['hydra:member']]
          this.isLoaded = true
        })
      }
    },
    orderDevicesByTotal() {
      this.totalOrder.isActive = true

      if (this.totalOrder.query === 'asc') this.totalOrder.query = 'desc'
      else this.totalOrder.query = 'asc'

      this.isLoaded = false
      this.devices = []

      if (this.currentUserRole === 'ROLE_ADMIN') {
        ApiService.get(
          'device_daily_cumulatives',
          `?oib=${this.currentUserCompanyOib}&order[total]=${this.totalOrder.query}&pagination=false`
        ).then(({ data }) => {
          this.devices = [...data['hydra:member']]
          this.isLoaded = true
        })
      }
    },
    orderDevicesByLastCheck() {
      this.lastCheckOrder.isActive = true

      if (this.lastCheckOrder.query === 'asc')
        this.lastCheckOrder.query = 'desc'
      else this.lastCheckOrder.query = 'asc'

      this.isLoaded = false
      this.devices = []

      if (this.currentUserRole === 'ROLE_ADMIN') {
        ApiService.get(
          'device_daily_cumulatives',
          `?oib=${this.currentUserCompanyOib}&order[updatedAt]=${this.nameOrder.query}&pagination=false`
        ).then(({ data }) => {
          this.devices = [...data['hydra:member']]
          this.isLoaded = true
        })
      }
    },
    exportAsExcel() {
      window
        .open(
          `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/exports/xlsx/current-today-company-revenue/${this.currentUserCompanyOib}`,
          '_blank'
        )
        .focus()
    },
    exportAsPDF() {
      window
        .open(
          `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/exports/pdf/current-today-company-revenue/${this.currentUserCompanyOib}`,
          '_blank'
        )
        .focus()
    },
  },
}
</script>

<style>
.vc-container {
  font-family: 'Poppins', sans-serif !important;
}
</style>
